
import { LoginService } from './services/login.service';
import { Injectable } from '@angular/core';
import { UtilidadesService } from '../../src/app/services/utilidades.service';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public loginService: LoginService,
    private router: Router,
    public utilidadesService:UtilidadesService
  ) { }

  async canActivate(): Promise<boolean> {
    if (!this.loginService.token) {
      this.loginService.doLogout();
      return false;
    } else {
      try {
        const validado = await this.loginService.validate_token();
        if (validado) {
          return true;
        } else {
          this.utilidadesService.mostrar_mensaje("Sesión finalizada");
          this.loginService.doLogout();
          this.router.navigate(['/login']);
          return false;
        }
      } catch (error) {        
        this.utilidadesService.mostrar_mensaje("Error interno del servidor");
        this.router.navigate(['/login']);
        return false;
      }
    }
  }

}